.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.stats_row_one {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  width: 100%;
  /* min-height: 150px; */
  margin-bottom: 24px;
}

.stats_row_two {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 24px;
  width: 100%;
  min-height: 300px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.stats_row_three {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 24px;
  width: 100%;
  min-height: 300px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.title_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.title {
  color: #003d82;
  font-size: 18px;
  margin: 0;
}

.meeting_info_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(0, 0, 0, 0.15); */
  width: 100%;
  height: 60px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2) !important;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.subtile {
  color: #003d82;
  margin: 0;
}

.top_stat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to bottom right, #464646, #000000);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  height: 100%;
  border-radius: 3px;
}

.top_stat_top {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  padding: 10px;
  /* height: 60%; */
}

.top_stat_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* height: 60px; */
}

.top_stat_bottom_content {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 10px;
  /* height: 20px; */
}

.top_stat_text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.top_stat_text_title {
  color: white;
  margin: 0;
  font-size: 35px;
  text-align: start;
}

.top_stat_text_subtitle {
  color: white;
  font-size: 15px;
  margin: 0;
  text-align: start;
}

.top_stat_line {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 1px;
  margin-top: 3px;
}

.mini_bar_chart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 30%;
}

.top_stat_bar {
  background-color: white;
  width: 20%;
  /* height: 40px; */
}

.tile {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  /* height: 100%; */
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  /* overflow-y: auto; */
}

.tile_scroll {
  height: 90%;
  overflow-y: auto;
  padding: 3px;
  box-sizing: border-box;
}

.last_updated {
  color: white;
  margin: 0;
  text-align: start;
  font-weight: 300;
  margin-left: 5px;
}

.clock_image {
  width: 16px;
  height: 16px;
}

.pie_chart_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  margin: 0;
  margin-top: 20px;
}

.profile_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.prospect_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.prospect_info_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: center; */
}

.prospect_name {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}

.prospect_email {
  margin: 0;
  font-size: 14px;
  font-weight: 200;
}

.meeting_info {
  margin: 0;
  color: #003d82;
  font-size: 16px;
  font-weight: 300;
}

.row_title {
  margin: 0;
  font-size: 22px;
  color: black;
  font-weight: 400;
  align-self: start;
  margin-bottom: 14px;
}

.activity_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
