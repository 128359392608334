.main_layout {
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: 100%;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 40px;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 88, 220, 0.65);
  margin-left: 50px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.update_profile_picture:hover {
  background-color: rgba(0, 88, 220, 0.8);
}

.profile_picture_bg {
  width: 150px;
  height: 150px;
  border-radius: 5px;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile_picture_section_left {
  width: 49%;
}
.profile_picture_section_right {
  width: 49%;
}

.single_line_input {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 15px;
}

.input_title {
  margin: 0;
  text-align: start;
  font-size: 16px;
  margin-bottom: 5px;
}

.button_section {
  margin-top: 30px;
  display: flex;
  justify-content: end;
  width: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  width: 49%;
  height: 50px;
  background-color: rgba(0, 88, 220, 0.8);
  border-radius: 5px;
}

.accountID {
  display: flex;
  width: 100%;
  height: 40px;
  font-weight: 300;
}

.accountIDBold {
  font-weight: 600;
  margin-right: 10px;
}
