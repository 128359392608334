.portal_navbar {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 60px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); /* Add the box shadow */
}

.portal_navbar_sidebar_extension {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  /* padding: 0 10px; */
}

.portal_navbar_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.portal_navbar_left_content {
  display: flex;
  flex-direction: row;
}

.portal_navbar_right_content {
  display: flex;
  flex-direction: row;
  align-items: end;
  position: relative;
}

.portal_navbar_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.portal_navbar_account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.navbar_item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.navbar_icon {
  width: 45%;
  height: 45%;
  opacity: 0.6;
  object-fit: cover;
}

.account_navbar_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  /* width: 100px; */
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 0 10px;
}

.navbar_item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.account_navbar_item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.account_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.account_pfp {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
}

.account_name {
  margin: 0;
  font-size: 13px;
  color: black;
  font-weight: 500;
}

.account_position {
  margin: 0;
  font-size: 13px;
  color: black;
  font-weight: 400;
}

.menu {
  position: absolute;
  right: 20px;
  top: 75px;
  width: 200px;
  /* height: 400px; */
  /* height: 200px; */
  /* background-color: white; */
  /* background-color: rgba(0, 0, 0, 0.85); */
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  /* border: 1px solid black; */
  z-index: 1;
}

.menu_item {
  display: flex;
  align-items: center;
  height: 50px;
}

.menu_item:hover {
  background-color: rgba(0, 61, 130, 0.75);
  border-radius: 5px;
}

.menu_item_text {
  color: black;
  opacity: 0.65;
  margin: 0;
  margin-left: 10px;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.menu_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.notifications_sidebar {
  position: fixed;
  top: 60px;
  left: 250px;
  width: 350px;
  height: calc(100vh - 80px);
  /* background-color: rgba(30, 30, 30, 1); */
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4) !important;
  z-index: 999999;
  padding: 20px;
  /* your other styles */
}

.exit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.exit_button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.exit_text {
  color: black;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
}

.edit_chatbot_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.edit_chatbot_button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.hide_info_text {
  color: black;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
}

.switch_info_button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 175px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.switch {
  width: 60px;
  height: 24px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.switch.on {
  background-color: rgba(0, 61, 130, 1);
}

.slider {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 15px;
  position: absolute;
  transition: transform 0.2s ease-in-out;
}

.switch.on .slider {
  transform: translateX(36.3px);
}
