.home_components {
  width: 100vw;
  overflow: hidden;
  /* height: 100vh; */
}

.landing_image {
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

.navbar_box {
  /* background: rgba(255, 255, 255, 1); */
}

.home_header_content {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);

  z-index: 1;

  /* border-radius: 10px; */
}

.landing_header {
  /* background: rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  margin-left: 50px;
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.title_copywriting {
  color: black;
  font-size: 35px;
  text-align: start;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subtitle_copywriting {
  color: black;
  font-size: 20px;
  text-align: start;
  margin: 0;
  margin-bottom: 10px;
}

.summary_copywriting_box {
  width: 70%;
}

.summary_copywriting {
  color: #e50019;
  font-size: 22px;
  margin: 0;
  margin: 30px;
}

.buttons {
  display: flex;
  flex-direction: row;
  /* width: 25%; */
  height: 75px;
  justify-content: space-between;
  margin-bottom: 50px;
}

.trial_button_text {
  /* color: #E50019; */
}
.trial_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 100%;
  color: #e50019;
  /* background-color: #E50019; */
  border: 2px solid #e50019;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 30px;
}

.trial_button:hover {
  color: white;
  background-color: #e50019;
  cursor: pointer;
}

.demo_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
}
.demo_button_text {
  color: #e50019;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* top: -70px; */
  width: 100%;
  height: 575px;
  background-image: linear-gradient(to bottom left, #0a0a0a, #e50019);
  /* clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%); */
  z-index: 9999; /* Optional: this may be necessary if you want this element to be on top of others */
  margin-bottom: 30px;
}

.banner_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 65%;
}

.banner_title {
  color: white;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

.banner_content_text {
  color: white;
  margin: 0;
  font-size: 25px;
  font-weight: 200;
  margin-top: 30px;
  margin-bottom: 40px;
}

.banner_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  counter-reset: #e50019;
  color: #e50019;
  border-radius: 5px;
  width: 150px;
  height: 55px;
  cursor: pointer;
}

.banner_button:hover {
  background-color: #e50019;
  color: white;
}

.section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  /* height: 550px; */
}

.easier_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  justify-items: center;
  align-items: center;
  width: 75%;
}

.easy_card {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 285px;
  height: 285px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
  box-sizing: border-box;
  padding: 15px;
  /* padding: 50px; */
}

.easy_card:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3) !important;
  border: 1px solid #e50019;
}

.value_prop_circle {
  margin-top: 5px;
  background-color: #e50019;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important; */
}

.value_prop_image {
  width: 100%;
  height: 100%;
}

.value_prop_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value_prop_title {
  margin: 0;
  font-size: 21px;
  margin-top: 15px;
  margin-bottom: 7.5px;
  color: #e50019;
  /* margin-bottom: 7.5px; */
}

.value_prop_subtitle {
  margin: 0;
  margin-top: 10px;
  font-size: 13px;
  /* text-align: start; */
  flex-grow: 1;
  font-weight: 300;
}

.banner_two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* top: 100px; */
  margin-top: 100px;
  margin-bottom: 30px;
  width: 100%;
  /* height: 550px; */
  background-image: linear-gradient(to top right, #001f41, #e50019);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  z-index: 9999; /* Optional: this may be necessary if you want this element to be on top of others */
}

.banner_content_left {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 60%;
}

.banner_title_medium {
  margin: 0;
  font-size: 22px;
  text-align: start;
  color: white;
  align-self: start;
  font-weight: 600;
  margin-top: 100px;
}

.banner_title_small {
  margin: 0;
  font-size: 22px;
  text-align: start;
  color: white;
  align-self: start;
  font-weight: 600;
}

.banner_paragraph_top {
  margin: 0;
  font-size: 16px;
  text-align: start;
  color: white;
  margin-bottom: 40px;
  margin-top: 5px;
  font-weight: 200;
}

.banner_paragraph {
  margin: 0;
  font-size: 16px;
  text-align: start;
  color: white;
  margin-bottom: 100px;
  margin-top: 5px;
  font-weight: 200;
}

.expierence_section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  margin-top: 50px;
}

.expierence_title {
  color: #e50019;
  margin: 0;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 20px;
  width: 100%;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.expierence_logo {
  width: 13vw;
  object-fit: cover;
  margin-bottom: 20px;
  /* margin-left: 55px;
  margin-right: 55px; */
}

.expierence_logo_sm {
  height: 4vw;
}

.letter_section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
}
.letter_content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px; */
  width: 90%;
  background-image: linear-gradient(to right, #001f41, #e50019);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 50px;
}

.letter_textbox {
  /* display: flex;
  justify-content: center;
  align-items: ; */
}

.letter_text {
  font-size: 15px;
  color: white;
  text-align: start;
  cursor: pointer;
}

.hyperlink {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.hyperlink:hover {
  opacity: 0.7;
}

.skeptical_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #ededed; */
  width: 100%;
  /* height: 500px; */
  padding-left: 200px;
  padding-right: 200px;
  box-sizing: border-box;
}

.skeptical_title {
  margin-top: 10px;
  font-size: 30px;
  color: #e50019;
  font-weight: 200;
  margin: 0;
  margin-top: 50px;
}

.skeptical_text {
  font-size: 17px;
  color: #e50019;
  text-align: start;
  font-weight: 300;
  margin-bottom: 70px;
}

@media (max-width: 1200px) {
  .easier_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    justify-items: center;
    align-items: center;
    width: 75%;
  }
}

@media (max-width: 768px) {
  .main_copywriting {
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .easier_cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 80px;
    justify-items: center;
    align-items: center;
    width: 75%;
  }
  .banner_title {
    color: white;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }

  .banner_content_text {
    color: white;
    margin: 0;
    font-size: 18px;
    font-weight: 200;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .replyassist_will {
    font-size: 13px;
    margin-right: 5px;
    /* opacity: 0; */
  }

  .word {
    font-size: 13px;
    /* opacity: 0; */
    /* padding-bottom: -20px; */
  }

  .title_copywriting {
    font-size: 25px;
  }

  .subtitle_copywriting {
    font-size: 13px;
  }

  .skeptical_section {
    padding-left: 50px;
    padding-right: 50px;
  }
  .banner {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .banner_two {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  }
  .trial_button_text {
    /* color: #E50019; */
    font-size: 18px;
  }
  .trial_button {
    width: 200px;
    height: 50px;
  }
}

.linkedInLink {
  text-decoration: underline;

  font-size: 16px;
  text-align: start;
  color: white;

  font-weight: 200;
}

.linkedInLink:hover {
  opacity: 0.7;
}

.spacer {
  height: 75px;
}
