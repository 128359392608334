.main_layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 150px; */
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 24px;
}

.top_controls {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  /* margin-bottom: 20px; */
}

.top_control_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  margin-left: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.top_buttons:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.header_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  margin-top: 24px;
}

.left_search_filter_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search_filter_controls {
  display: flex;
  width: 100%;
}

.search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  width: 400px;
  margin-right: 15px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.search_icon {
  width: 20px;
  height: 20px;
  opacity: 0.25;
  margin-left: 10px;
}

.search_divider {
  height: 50%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  margin-right: 5px;
}
.search_input {
  height: 80%;
  color: rgba(0, 0, 0, 1);
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
}

.search_input::placeholder {
  opacity: 0.5;
}

.search_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  width: 200px;
  margin-right: 15px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* box-sizing: border-box; */
  padding: 0 10px;
  cursor: pointer;
}

.search_filter:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.search_filter_icon {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.filter_text {
  display: flex;
  text-align: start;
  flex-grow: 1;
  margin-left: 5px;
}

.down_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.table_results_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  padding: 0px 24px;
}

.page_result_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_result_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 50px;
  margin-left: 15px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* box-sizing: border-box; */
  padding: 0 10px;
  cursor: pointer;
}

.page_result_filter:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.table_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.table_scroll {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  height: 600px;
}

.table_info_section {
  background-color: rgba(0, 0, 0, 0.2);
}

.checkbox_section {
  display: flex;
  width: 60px;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
}

.data_section {
  display: flex;
  width: 15%;
}

.showing_results {
  opacity: 0.6;
}

.boldText {
  font-weight: 600;
}
