.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.table_scroll {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  height: 600px;
}

.classifications_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 110px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
}

.classifications_image {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
}

.classifications_info {
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin-left: 10px;
}

.classifications_subinfo {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.classification_title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 2.5px;
}

.classification_sub_title {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 5px;
}

.classification_info_title {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  opacity: 0.8;
  margin-bottom: 3px;
}
