.home_components {
  width: 100vw;
  /* height: 100vh; */
}

.landing_image {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.page_title {
  color: #e50019;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
}
.join_waitlist {
  color: #e50019;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 15px;
}

.sidebar {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: white;
  border-right: 2px solid #e50019;
  box-sizing: border-box;
  padding: 30px;
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;

  background: rgba(229, 0, 25, 0.05);
  margin-bottom: 15px;
}

.input {
  background-color: transparent;
  margin-left: 10px;
  border: none;
  outline: none;
  flex-grow: 1;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.join_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(229, 0, 25, 0.7);
  color: white;
  cursor: pointer;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(229, 0, 25, 0.9);
  color: white;
}

.join_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(229, 0, 25, 0.2);
  color: #e50019;
  opacity: 0.5;
  cursor: pointer;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;
    border-right: 2px solid #e50019;
    box-sizing: border-box;
    padding: 30px;
  }
}

.note {
  color: #e50019;
  opacity: 0.5;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.logo {
  width: 100px;
  object-fit: cover;
}

.waitlist_confirmation_layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.checkmark_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(229, 0, 25, 0.1);
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important; */
}

.checkmark {
  width: 30%;
  height: 30%;
  object-fit: cover;
}

.waitlist_confirmation_text {
  margin: 0;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(229, 0, 25, 0.9);
}

.home_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 50px;
  margin-top: 35px;
  background-color: rgba(229, 0, 25, 0.8);
  border: 1px solid rgba(229, 0, 25, 0);
  color: white;
  cursor: pointer;
}

.home_button:hover {
  background-color: white;
  border: 1px solid rgba(229, 0, 25, 1);
  color: rgba(229, 0, 25, 1);
}
